// Enabling shadows and gradients
$enable-shadows: true;
$enable-gradients: true;

// Changing the body background and text
$body-bg: #f3f1ed;
$body-color: #333333;

// Font
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Helvetica', 'Arial', Roboto, 'Noto Sans', sans-serif;

// Navbar
$navbar-padding-x: 1rem;
$navbar-padding-y: 1rem;

// Changing the border radius of buttons
$border-radius: 8px;

// Button
$btn-font-size: 14px;
$btn-font-weight: 400;
$btn-border-radius: 0px;
$btn-border-width: 2px;
$btn-focus-box-shadow: 0px;
$btn-padding-x: 1.5rem;
$btn-padding-y: 0.45rem;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

// Form
$input-border-radius: 0px;
$input-border-width: 0px;
$input-placeholder-color: #999999;
$input-font-size: 12px;
$input-border-color: #efefef;
$input-box-shadow: none;
$input-group-addon-bg: #ffffff;
$input-group-addon-border-color: #efefef;
$input-padding-x: 0.6rem;
$input-padding-y: 0.6rem;
$input-height: calc(1.4em + 1.2rem + 2px);
$form-check-input-margin-y: 3px;

//Changing the theme colors
$primary: #ffb81c;
$primary-dark: #eca200;
$secondary: #e9e9e9;
$supply: #999999;
//$success: #2bc550;
//$info: #495dff;
//$warning: #ef8143;
$danger: rgba(204, 74, 74, 1);
//$light: #dfe6ee;
$dark: #666666;
$light-grey: #bebebe;
$transparent: transparent;
// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
//$theme-colors: (
//  "cool": #4d3fa3
//);

// Breadcrumb
$breadcrumb-bg: none;
$breadcrumb-border-radius: 0px;
$breadcrumb-padding-x: 15px;
$breadcrumb-padding-y: 0px;
$breadcrumb-margin-bottom: 0.25rem;

// List-Group Items
$list-group-border-width: 0px;
$list-group-item-padding-x: 0.6rem;
$list-group-item-padding-y: 0.4rem;
$list-group-active-bg: ($secondary);
$list-group-action-color: #333333;
$list-group-active-color: #333333;

// Dropdown Menu
$dropdown-border-radius: 5px;
$dropdown-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

// Card
$card-border-width: 0px;
$card-border-radius: 5px;

// Table
$table-bg: #ffffff;
$table-head-bg: #fafafa;
$table-border-color: #797979;
$table-border-width: 0px;
$table-hover-bg: rgb(255, 250, 238);
$table-accent-bg: rgba(0, 0, 0, 0.05);
$table-cell-padding: 10px;

// Pagination
$pagination-padding-x: 5px;
$pagination-padding-y: 5px;
$pagination-border-width: 0px;
$pagination-color: #333333;
$pagination-bg: transparent;

// Badge
$badge-font-size: 110%;
$badge-border-radius: 3px;

//Modal-Box
$modal-content-border-width: 0px;
$modal-content-box-shadow-sm-up: 0 0.05rem 0.3rem rgba(0, 0, 0, 0.2);
$modal-content-box-shadow-xs: 0 0.05rem 0.3rem rgba(0, 0, 0, 0.2);
$modal-header-padding: 12px 18px;
// $modal-inner-padding: 0 18px 18px 18px;
$close-font-weight: normal;

// Alert
$alert-border-radius: 5px;
$alert-padding-x: 0.65rem;
$alert-padding-y: 0.45rem;

// Toast
$toast-background-color: rgba(0, 0, 0, 0.8);
$toast-max-width: 420px;
$toast-color: #ffffff;
$toast-border-width: 0px;

// Custom Checkboxes
$custom-checkbox-indicator-border-radius: 3px;

// Dropdown
$dropdown-border-width: 0px;
$dropdown-border-radius: 0px;

// Tooltip
$tooltip-font-size: 0.75rem;
$tooltip-border-radius: 3px;