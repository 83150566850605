// Add additional styles here. For example, overwrite certain styles or add new components.
// Tip: You can use bootstrap's powerful mixins here!
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
a {
  @include hover {
  color: ($light-grey);
  text-decoration: none;
  background-color: transparent;
  }
}
p {
  font-size: 0.85rem;
  line-height: 1.3;
  letter-spacing: 0.3px;
}
header {
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
  background: #ffffff;
  z-index: 999;
  .logo {
    width: 245px;
    display: inline-block;
  }
}
.top-head {
  margin: 0;
  height: 40px;
  background: ($primary);
  .quick-links {
    margin: 6px auto;
  }
  .quick-links li {
    font-size: 12px;
    font-weight: 600;
    display: inline-flex;
    margin-left: 15px;
  }
  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    @include hover {
      text-decoration: underline;
    }
  }
}
.navbar {
  background: #000000;
}
.profile-image {
  background: ($light);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  vertical-align: middle;
}
.greeting {
  font-size: 14px;
  font-weight: 500;
  overflow: auto;
  line-height: 2.6;
  height: 30px;
  color: ($white);
}
.btn {
  font-family: ($font-family-sans-serif);
  font-weight: 600;
  border: 2px solid ($primary);
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  @include hover {
    color: ($black);
    text-decoration: none;
    background: ($primary);
  }
  &.btn-sign-in,
  &.btn-allow,
  &.btn-deny {
    width: 145px;
  }
  &.btn-dropdown {
    background-color: #e9e9e9;
    color: #333333;
  }
  &.btn-sign-up {
    color: ($black);
    border: 2px solid ($black);
    @include hover {
      color: ($primary);
      background: ($black);
    }
  }
  &.btn-filter {
    background-color: #b2b2b2;
    font-size: 11px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 5px;
    padding: 0.2rem 0.45rem;
    width: 120px;
    @include hover {
      background-color: ($primary);
    }
    &.active {
      background-color: ($primary);
    }
  }
}
.btn-action-menu {
  background: transparent;
  border: 0px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  @include hover {
    cursor: pointer;
    background: #e4e4e4;
  }
}
.btn-google {
  background: url(./assets/images/btn_google.svg);
  background-repeat: no-repeat;
  -webkit-background-size: 42px 42px;
  background-size: 42px 42px;
  background-color: ($white);
  width: 180px;
  height: 42px;
  margin-left: 10px;
  border: none;
  -webkit-box-align: center;
  align-items: center;
  align-self: center;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 1;
  flex: 1 1 auto;
  margin: 0px 4px;
  overflow: hidden;
  transition: opacity 0.3s ease 0s;
}
.other-sign-in {
  font-weight: 600;
  letter-spacing: 0px;
  color: ($light-grey);
}
.continue-google {
  font-weight: 600;
  text-align: center;
  color: #666666;
  margin: 8px 0px 0px 25px;
}
.action-icons button:last-child {
  padding: 0 5px;
}
button.material-icons {
  background: none;
  border: 0px;
  padding: 0;
}
button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
hr {
  margin-top: 0.8rem;
  border-top: 1px solid ($primary-dark);
}
.text-link a {
  color: ($primary);
  @include hover {
    color: ($white);
    text-decoration: none;
    cursor: pointer;
  }
}
#user-id {
  cursor: pointer;
}
#user-id .dropdown-menu {
  z-index: 0;
  margin-top: 13px;
}
#user-id .dropdown-item {
  font-size: 0.9rem;
}
.panel-login {
  background-color: ($black);
  border: 0px;
  border-radius: 0px;
  width: 95%;
  max-width: 560px;
  margin: 180px auto 40px auto;
  font-size: 12px;
  text-align: left;
  color: ($white);
  .panel-head {
    height: 10px;
    background-color: ($primary);
  }
  .logo {
    margin: 0 auto;
    width: 200px;
  }
  .sign-in {
    font-size: 13px;
  }
  .remember-password .form-check {
    padding-left: 2.2rem;
  }
}
.panel-title {
  color: ($primary);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
  text-align: center;
}
.panel-description {
  color: ($light-grey);
  font-size: 13px;
  line-height: 1.3;
  text-align: center;
}
.agree-terms {
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  margin-top: 30px;
}
.reminder-message {
  color: ($primary);
}
label {
  font-weight: 500;
}
.copyright {
  text-align: center;
  font-size: 10px;
}

// Breadcrumb
.breadcrumb h6 {
  font-size: 12px;
  font-weight: normal;
}

// Heading
.heading {
  h5 {
    margin-bottom: 0px;
  }
}
.icon-title {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  margin-right: 8px;
  &.icon-sign-up {
    background-image: url("./assets/images/icon_sign-up.svg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: 28px 28px;
    width: 28px;
  }
}
.sub-title {
  margin: 30px auto 15px auto;
  &.access-plat {
    background-image: url("./assets/images/icon_access-platform.svg");
    background-position: left 0px;
    background-repeat: no-repeat;
    background-size: 28px;
  }
  &.refer-program {
    background-image: url("./assets/images/icon_referral.svg");
    background-position: left 0px;
    background-repeat: no-repeat;
    background-size: 22px;
  }
  &.special-offers {
    background-image: url("./assets/images/icon_referral.svg");
    background-position: left 0px;
    background-repeat: no-repeat;
    background-size: 22px;
  }
  &.sf-club {
    background-image: url("./assets/images/icon_sf-cloud.svg");
    background-position: left 0px;
    background-repeat: no-repeat;
    background-size: 20px;
  }
  &.competitions {
    background-image: url("./assets/images/icon_sf-cloud.svg");
    background-position: left 0px;
    background-repeat: no-repeat;
    background-size: 20px;
  }
}
.benefits {
  font-size: 0.85rem;
  .sub-title {
    font-weight: 600;
    color: ($primary-dark);
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 10px;
    padding-inline-start: 30px;
  }
  ul {
    padding: 0 15px;
  }
  li {
    list-style-type: disc;
  }
}
// Content
.note p {
  font-weight: 600;
  color: ($primary-dark);
  font-size: 0.8rem;
}
.subscribe-news p {
  font-size: 0.9rem;
}
#wrapper {
  margin-top: 35px;
}

// Pagination
#count-records {
  font-size: 11px;
}
.rows-per-page,
.go-to-page {
  font-size: 12px;
  font-weight: bold;
  color: #666666;
}
.page-box {
  border: 0px;
  color: #333333;
  padding: 6px 8px;
  max-width: 42px;
  height: 30px;
  text-align: center;
}
.pagination {
  font-size: 12px;
}

// Card Panel
.card {
  & .content {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.4;
  }
}
.main-panel .card {
  & .title {
    font-size: 12px;
    font-weight: 500;
    color: ($primary);
  }
  & .data {
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
  }
  & .time {
    font-size: 11px;
    font-weight: normal;
  }
}

// Modal Box
.modal-title,
.modal-description {
  font-size: 12px;
}
.modal-header .close {
  padding: 10px 15px;
}

// Form
input,
textarea {
  border-radius: 0px !important;
}
input[type="checkbox"],
input {
  font-size: 1rem;
  margin-right: 10px;
}
input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}
label {
  font-size: 0.8rem;
  color: ($dark);
}
.panel-login label {
  color: ($white);
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 8px top 50%;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  cursor: pointer;
}
.field-title {
  font-weight: 600;
  font-size: 0.85rem;
  text-transform: uppercase;
  color: ($black);
  margin: 8px auto 12px auto;
}
textarea {
  /*resize: none;*/
}
.checkbox {
  font-size: 0.85rem;
}
sup {
  color: ($danger);
  font-size: 90%;
  top: -0.25em;
}
.form-control {
  &.is-valid {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'><path fill='%2328a745' d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/></svg>");
  }
  &.is-error {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path fill='%23cc4a4a' d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");  
    padding-right: calc(1.5em + 1.2rem);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.3rem) center;
    -webkit-background-size: calc(0.75em + 0.6rem) calc(0.75em + 0.6rem);
    background-size: calc(0.75em + 0.6rem) calc(0.75em + 0.6rem);
  }
}
#form-login {
  .invalid-feedback {
  color: #ffffff;
  font-size: 95%;
  margin-top: 0;
}
}
.storeurl-group {
  display: none;
}
// Alert
.alert {
  line-height: 1.2;
  color: ($black);
  p {
    font-size: 12px;
  }
  a {
    color: ($black);
    text-decoration: underline;
    @include hover {
      color: #17a2b8;
    }
  }
}
.alert-heading {
  font-size: 12px;
  color: #17a2b8;
}
.alert-info {
  position: relative;
  padding: 0.65rem 0.85rem;
  margin-bottom: 1rem;
  border: 0px;
  background: #ddefee;
  /* border-radius: 5px; */
}
// Footer
footer {
  margin: 50px auto;
}
.other-links {
  font-weight: 600;
  font-size: 0.65rem;
  text-align: center;
  ul {
    list-style-type: none;
    padding-inline-start: 0px;
    margin-bottom: 0px;
    li {
      &::after {
        content: "|";
        margin: 0 5px;
      }
    }
    li:last-child {
      &::after {
        content: "";
      }
    }
  }
}
.tooltip {
  left: -10px !important;
}
.tooltip-list {
  text-align: left;
  list-style-type: none;
  padding: 0.25rem;
  margin: 0;
  .material-icons {
    color: $primary;
    padding-left: 0;
    padding-right: 0.5rem;
    font-size: 0.85rem;
    &.md-inactive {
      opacity: 0.4;
    }
  }
}
