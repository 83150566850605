// laptop display
@media (max-width: 1439.98px) {

}

// xl breakpoints
@media (max-width: 1199.98px) {

}
@media (min-width: 1200px) {
}

// lg breakpoints
@media (max-width: 991.98px) {
  .container,
  .container-sm {
    width: 90%;
    max-width: 90%;
  }
  #hamburger-menu {
    z-index: 999;
  }
}
@media (min-width: 992px) {
}

// md breakpoints
@media (max-width: 767.98px) {
  /*header {
    height: 60px;
  }*/
}
@media (min-width: 768px) {
}

// sm breakpoints
@media (max-width: 575.98px) {
  body {
    font-size: 0.9rem;
  }
  h4, .h4 {
    font-size: 1.3rem;
  }
  .container {
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .app-list {
    height: 400px important;
  }
}

// print
@media print {
}

// mobile menu
.mobile-nav {
  z-index:2;
	position: relative;
	height: 48px;
	cursor: pointer;
}
.bar,
.bar:after,
.bar:before {
  width: 30px;
	height: 2px;
}
.bar {
	position: relative;
	transform: translateY(25px);
	background: rgba(255, 255, 255, 1);
	transition: all 0ms 300ms;
}
.bar.animate {
  background: rgba(255, 255, 255, 0); 
}
.bar:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 10px;
	background: rgba(255, 255, 255, 1);
	transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.bar:after {
	content: "";
	position: absolute;
	left: 0;
	top: 10px;
	background: rgba(255, 255, 255, 1);
	transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.bar.animate:after {
	top: 0;
	transform: rotate(45deg);
	transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}
.bar.animate:before {
	bottom: 0;
	transform: rotate(-45deg);
	transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}
#mobile-menu {
  &.overlay-bg {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: ($light);
    display: none;
    z-index: 1200;
  }
  .wrapper {
    margin-top: 80px;
    width: 80%;
  }
  .close-menu {
    position: absolute;
    top: 15px;
    right: 30px;
    cursor: pointer;
    .bar {
      background-color: transparent;
      &:before {
        background-color: ($black);
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
      }
      &:after {
        background-color: ($black);
        top: 0;
        transform: rotate(45deg);
        transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
      }
    }
  }
  ul {
    list-style: none;
  }
}
