@import 'custom-variables';
@import '~bootstrap/scss/bootstrap';
@import 'custom-styles';
@import 'responsive';
@import '~@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';
.top-head {
  height: 10px;
}

.bg-user {
  background: url(./assets/images/bguser.png) no-repeat;
  background-size: cover;
  border-radius: 0;
}

.edit-btn {
  right: 0;
  top: -3px;
}

.form-control,
.form-control:focus {
  background-color: #fff !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
}

.custom-control-label::before {
  border: #adb5bd solid 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.loader-container {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.btn-dark-border {
  border-color: #000;
  background: #fff;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #ffb81c;
  text-decoration: none;
  background: transparent;
  font-weight: 600;
}

.app-list {
  height: 400px;
  overflow: hidden;
  overflow-y: auto;
}

.pagination {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination span {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
}
.pagination span.active {
  background: #ffc107;
  width: 11px;
  height: 11px;
}
.pagination span:before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  border-radius: 50%;
  border: 1px solid #ffc107;
}

.collapse {
  .card-body {
    padding-left: 0.5rem;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #dddddd !important;
}

input.form-control-lg {
  padding: 0.5rem 1rem !important;
  font-size: 0.75rem !important;
  height: 46px;
}

.btn-photo {
  background-color: #ffffff;
  border-color: #fff;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 80px;
  right: 45px;
  i {
    font-size: 1.5em;
    line-height: 1.3;
  }
}

.btn-photo-remove {
  left: 45px;
}